.div-main-completion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-left: 300px;
  padding-right: 300px;
}

/* completion.css */

body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

.div-main-completion {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.div-main-completion h1 {
  color: #2ecc71;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.div-main-completion p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.div-resumen-cart,
.div-info-envio,
.div-info-pago,
.div-next {
  margin-top: 2rem;
  text-align: left;
}

.div-resumen-cart h2,
.div-info-envio h2,
.div-info-pago h2,
.div-next h2 {
  color: #3498db;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.div-resumen-cart ul {
  list-style: none;
  padding: 0;
}

.div-resumen-cart ul li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #ececec;
  padding-bottom: 0.5rem;
}

.div-info-envio p,
.div-info-pago p,
.div-next p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.div-buttons {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.div-buttons button {
  background-color: #ffb8b7 !important;
  color: #fff;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.div-buttons button:hover {
  background-color: #c28c8bfa !important;
  transform: translateY(-3px);
}

.div-buttons button:active {
  transform: translateY(0);
  background-color: #1f618d;
}

.link-completion{
  background-color: #ffb8b7 !important;
  color: #fff;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.link-completion:hover {
  background-color: #c28c8bfa !important;
  transform: translateY(-3px);
}

.link-completion:active {
  transform: translateY(0);
  background-color: #1f618d;
}

