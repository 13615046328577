input[type="text"] {
    padding-left: 10px; /* Ajusta el valor según lo necesites */
}

input[type="number"] {
    padding-left: 10px; /* Ajusta el valor según lo necesites */
}

.div-main-shipping{
    padding-top: 70px !important;
    padding-left: 300px !important;
    padding-right: 300px !important;
  }

  @media (max-width: 767px) {
    .div-main-shipping{
      padding-top: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }