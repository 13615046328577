.div-login-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 350px;
  padding-right: 350px;
}

.div-login-main-profile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.div-google-login {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 3rem;
  padding-left: 9rem;
  padding-right: 9rem;
}

.img-responsive {
  width: 100%;
  height: auto;
}

/* Estilos para dispositivos móviles */
@media (max-width: 767px) {
  .div-login-main {
    flex-direction: column; /* Apila los elementos verticalmente */
    padding-left: 50px;
    padding-right: 50px;
  }

  .div-google-login {
    flex-direction: column; /* Apila los elementos verticalmente */
    padding-left: 50px;
    padding-right: 50px;
  }

  .img-responsive {
    width: 100%;
    height: auto;
  }

  .div-menu-mobile {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 3rem;
    padding-left: 9rem;
    padding-right: 9rem;
  }
}
