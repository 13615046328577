.div-payment{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.div-payment-info{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 40px;
}

/* Desktop */
@media (min-width: 768px) {
    .div-payment{
        padding-top: 50px;
        padding-left: 300px;
        padding-right: 300px;
    }

    .div-payment-info{
        padding-left: 300px;
        padding-right: 300px;
    }
}

@media (max-width: 767px) {
    .div-payment{
        padding-top: 10px !important;
        padding-left: 10 !important;
        padding-right: 10px !important;
    }

    .div-payment-info{
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}