.floating-whatsapp {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366; /* Color de WhatsApp */
    color: white;
    font-size: 40px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    text-decoration: none;
    transition: transform 0.3s ease;
    z-index: 1000;
  }
  
  .floating-whatsapp:hover {
    transform: scale(1.1);
  }
  