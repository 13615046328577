input[type="email"] {
    padding-left: 10px; /* Ajusta el valor según lo necesites */
}

.color-button{
    background-color: #ffb8b7;
    color: white;
  }

  .color-button :hover{
    background-color: #ffe4e3 !important;
    color: white  !important;
  }

  .div-main-personal-detail{
    padding-top: 70px !important;
    padding-left: 300px !important;
    padding-right: 300px !important;
  }

  @media (max-width: 767px) {
    .div-main-personal-detail{
      padding-top: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }