.option-one-step {
  background-color: #ffb8b7;
  color: white;
}

.option-two-step {
  color: #ffb8b7;
  border-color: #ffb8b7;
}

.option-tree-step {
  color: #4caf4fc2;
  border-color: #4caf4f75;
}

.color-text-primary {
  color: #4caf4fc2;
}

.color-button {
  background-color: #ffb8b7;
  color: white;
}

.color-button:hover {
  background-color: #ffe4e3 !important;
  color: white !important;
}

.step-item {
  flex: 1 1 100%; /* Asegura que cada paso ocupe el 100% del ancho en pantallas pequeñas */
  max-width: 100%; /* Asegura que los pasos no se desborden */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem; /* Espacio entre los pasos */
}

.step-connector {
  flex: 1 1 auto;
  border-top: 2px solid;
  margin: 0 1rem;
}

/* Desktop */
@media (min-width: 768px) {
  .div-buttons-buy {
    display: flex; /* flex */
    flex-direction: row; /* flex-row */
    height: 100%; /* h-full */
    width: 100%; /* w-full */
    justify-content: space-between; /* justify-between */
    padding-bottom: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .div-main-buy {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 50px !important;
  }

  .step-item {
    flex: 1 1 auto; /* Permite que los pasos ocupen el espacio disponible en pantallas más grandes */
    max-width: none;
  }

  .steps-container {
    display: flex;
    flex-direction: row !important; /* Asegura que los pasos se muestren en una sola fila */
    justify-content: center !important; /* Centra los elementos horizontalmente */
    padding-left: 80px;
  }
}

/* Mobile*/
@media (max-width: 767px) {
  .div-buttons-buy {
    display: flex; /* flex */
    flex-direction: row; /* flex-row */
    height: 100%; /* h-full */
    width: 100%; /* w-full */
    justify-content: space-between; /* justify-between */
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  .div-main-buy {
    padding-top: 0px !important;
  }

  .step-item {
    display: none; /* Oculta todos los pasos */
  }

  .step-item.active {
    display: flex; /* Muestra solo el paso activo */
  }

  .step-connector {
    display: none; /* Oculta el step-connector */
  }

  .steps-container {
    margin-top: 20px !important;
    padding-left: 0px !important;
  }
}
