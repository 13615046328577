.color-button {
  background-color: #ffb8b7;
  color: white;
}

.div-basic-information {
  border: 1px;
  border-color: #ffb8b7;
  box-shadow: #ffb8b7 20px 20px 20px;
  border-style: solid;
  border-radius: 20px;
  width: max-content;
}

.containerMovil {
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

.div-email-state {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.div-buttons-main {
  padding-left: 20px; /* pl-20 */
  padding-right: 20px; /* pr-20 */
  margin-top: 6px; /* mt-6 */
  margin-bottom: 6px; /* mb-6 */
  display: flex; /* flex */
  align-items: center; /* items-center */
  justify-content: space-between; /* justify-between */
  gap: 6px; /* gap-x-6 */
}

.div-main-products {
  padding-left: 5rem; /* pl-20 */
  padding-right: 5rem; /* pr-20 */
  padding-top: 2.5rem; /* pt-10 */
  padding-bottom: 3rem; /* pb-12 */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* border-b border-gray-900/10 */
  margin-bottom: 2.5rem; /* mb-10 */
}

/* .div-main-state {
  display: flex !important; 
  flex-direction: row !important; 
  width: 100% !important; 
} */

.div-main-email {
  display: flex;
  flex-direction: column;
}

/* media query para celular */
@media (max-width: 767px) {
  .div-main-email {
  }

  .containerMovil {
    margin-top: 32px;
  }

  .containerMovil .table-auto {
    width: 100%;
    overflow-x: auto;
    display: flex;
  }

  .div-email-state {
    flex-direction: column;
  }

  .div-base-information {
    display: flex;
    flex-direction: column;
    padding-left: 1rem /* 20px */;
    padding-right: 1rem /* 20px */;
    padding-top: 1rem /* 12px */;
    padding-bottom: 1rem /* 12px */;
    margin: 16px !important;
    border: 1px;
    border-color: #ffb8b7;
    box-shadow: #ffb8b7 10px 10px 10px;
    border-style: solid;
    border-radius: 20px;
  }

  .div-buttons-main {
    padding-left: 20px !important; /* pl-20 */
    padding-right: 20px; /* pr-20 */
    padding-top: 30px; /* mt-6 */
    margin-bottom: 30px; /* mb-6 */
    display: flex; /* flex */
    align-items: center; /* items-center */
    justify-content: space-between; /* justify-between */
    width: 100%;
  }

  .containerMovil ul {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-x: auto;
  }

  .containerMovil .table-auto thead {
    display: none;
  }

  .table-auto tbody {
    width: 100%;
  }

  .containerMovil .table-auto tr {
    display: block;
    margin-bottom: 10px; /* Separación entre "tarjetas" */
  }

  .containerMovil .table-auto td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
  }

  .containerMovil .table-auto td::before {
    content: attr(data-label); /* Agrega el encabezado como etiqueta */
    font-weight: bold;
    flex-basis: 50%;
    text-align: left;
  }

  .div-base-information-shipping {
    display: flex;
    flex-direction: column;
    padding-left: 1rem /* 20px */;
    padding-right: 1rem /* 20px */;
    padding-top: 1rem /* 12px */;
    padding-bottom: 1rem /* 12px */;
    margin: 1rem /* 40px */;

    border: 1px;
    border-color: #ffb8b7;
    box-shadow: #ffb8b7 10px 10px 10px;
    border-style: solid;
    border-radius: 20px;
  }

  h1 {
    font-size: 2rem;
    padding-left: 40px;
  }

  .div-main-products {
    padding-left: 40px; /* pl-20 */
  }
}

/* Desktop */
@media (min-width: 768px) {

  .div-main-email {
    margin-right: 10px;
  }

  .div-main-state {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    margin-left: 0.5rem !important; /* ml-2 */
    margin-right: 0.5rem !important; /* ml-2 */
  }

  .div-main-products {
    padding-left: 40px; /* pl-20 */
  }

  .containerMovil {
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 100px;
    padding-top: 50px;
  }

  .div-base-information {
    display: flex;
    flex-direction: column;
    padding-left: 1rem /* 20px */;
    padding-right: 1rem /* 20px */;
    padding-top: 1rem /* 12px */;
    padding-bottom: 1rem /* 12px */;
    margin: 1rem !important;
    width: 100%;
    border: 1px;
    border-color: #ffb8b7;
    box-shadow: #ffb8b7 10px 10px 10px;
    border-style: solid;
    border-radius: 20px;
  }

  .div-base-information-shipping {
    display: flex;
    flex-direction: column;
    padding-left: 1rem /* 20px */;
    padding-right: 1rem /* 20px */;
    padding-top: 1rem /* 12px */;
    padding-bottom: 1rem /* 12px */;
    margin: 1rem /* 40px */;
    width: 100%;
    border: 1px;
    border-color: #ffb8b7;
    box-shadow: #ffb8b7 10px 10px 10px;
    border-style: solid;
    border-radius: 20px;
  }

  h1 {
    font-size: 2rem !important;
    padding-left: 40px !important;
  }

  .div-buttons-main {
    margin-top: 40px; /* mt-6 */
  }
}
